import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Text } from 'components';
import theme from 'utils/theme';
import CircleTick from 'assets/icons/payments/circle-tick.svg';
import CalculatingModal from './CalculatingModal';

interface ChecklistProps {
  list?: string[];
  onEnd: () => void;
  onProgress: (progress: number) => void;
  modalQuestion: string;
  modalAnswer1: string;
  modalAnswer2: string;
  showModal?: boolean;
}

interface ChecklistItem {
  title: string;
  opacity: number;
  delay: number;
}

const ListContainer = styled.div`
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
`;

const ListItemContainer = styled.div<{ opacity: number; delay: number }>`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  transition: opacity 0.5s ease;
  transition-delay: ${({ delay }) => delay}s;
  opacity: ${({ opacity }) => opacity};
`;

const ListTitle = styled(Text)`
  margin-left: 0.3125rem;
  font-size: 1rem;
  line-height: 140%;
`;

const timeout = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const Checklist: FC<ChecklistProps> = ({
  list,
  onEnd,
  onProgress,
  modalQuestion,
  modalAnswer1,
  modalAnswer2,
  showModal,
}) => {
  const middleIndex = Math.ceil((list?.length || 0) / 2);
  const [firstHalfList, setFirstHalfList] = useState<ChecklistItem[]>(
    list
      ?.slice()
      .splice(0, middleIndex)
      .map((title, index) => ({ title, opacity: 0, delay: index * 1.5 })) || [],
  );
  const [secondHalfList, setSecondHalfList] = useState<ChecklistItem[]>(
    list
      ?.slice()
      .splice(-middleIndex)
      .map((title, index) => ({ title, opacity: 0, delay: index * 1.5 })) || [],
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleFirstHalfList = async () => {
    await timeout(50);
    onProgress(50);
    setFirstHalfList(prev => {
      const newList = prev.map(p => ({
        ...p,
        opacity: 1,
      }));
      return newList;
    });

    await timeout(3000);
    if (showModal) {
      return handleOpenModal();
    }
    handleModalButtonClick();
  };

  const handleSecondHalfList = async () => {
    onProgress(100);
    setSecondHalfList(prev => {
      const newList = prev.map(p => ({
        ...p,
        opacity: 1,
      }));
      return newList;
    });
    await timeout(5000);
    onEnd();
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleModalButtonClick = () => {
    handleCloseModal();
    handleSecondHalfList();
  };

  useEffect(() => {
    handleFirstHalfList();
  }, []);

  const renderBulletpoints = (
    { title, opacity, delay }: ChecklistItem,
    index: number,
  ) => (
    <ListItemContainer opacity={opacity} delay={delay} key={index}>
      <CircleTick fill={theme.colors.green110} width={24} height={24} />
      <ListTitle>{title}</ListTitle>
    </ListItemContainer>
  );

  return (
    <ListContainer>
      {firstHalfList.map(renderBulletpoints)}
      {secondHalfList.map(renderBulletpoints)}
      <CalculatingModal
        isOpen={isModalOpen}
        onClose={handleModalButtonClick}
        modalQuestion={modalQuestion}
        modalAnswer1={modalAnswer1}
        modalAnswer2={modalAnswer2}
      />
    </ListContainer>
  );
};

export default Checklist;
