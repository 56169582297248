import React, { FC } from 'react';
import styled from 'styled-components';

import { Modal, PrimaryButton, Text } from 'components';

interface CalculatingModalProps {
  isOpen: boolean;
  onClose: () => void;
  modalQuestion: string;
  modalAnswer1: string;
  modalAnswer2: string;
}

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Heading = styled(Text).attrs({
  type: 'h4',
})`
  text-align: center;
  margin-bottom: 1.5rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ButtonStyled = styled(PrimaryButton)`
  width: 7rem;
  height: 2.25rem;
  min-height: 2.25rem;
  border-radius: 3.125rem;
`;

const CalculatingModal: FC<CalculatingModalProps> = ({
  isOpen,
  onClose,
  modalQuestion,
  modalAnswer1,
  modalAnswer2,
}) => (
  <Modal width={'18rem'} isOpen={isOpen}>
    <ContentContainer>
      <Heading>{modalQuestion}</Heading>
      <ButtonContainer>
        <ButtonStyled onClick={onClose}>{modalAnswer1}</ButtonStyled>
        <ButtonStyled onClick={onClose}>{modalAnswer2}</ButtonStyled>
      </ButtonContainer>
    </ContentContainer>
  </Modal>
);

export default CalculatingModal;
